<template>
  <q-page padding class="column content-center">
    <details-form />
    <m-cta-button :label="$t('continue')" :disabled="disabled" @ctaClick="saveInfo" />
  </q-page>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import detailsForm from './details-form.vue'
import { handleErrors } from 'utils/utils'
import { MCtaButton } from 'components'

export default {
  components: { detailsForm, MCtaButton },
  computed: {
    ...mapGetters({
      mainPassenger: 'onwardtravel/mainPassenger',
      racDetails: 'onwardtravel/racDetails',
      driverNotes: 'onwardtravel/driverNotes',
      internalNotes: 'onwardtravel/internalNotes'
    }),
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    }
  },
  methods: {
    setPassenger (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'mainPassenger', payload: this.mainPassenger })
    },
    setDriverNotes (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'driverNotes', payload: val })
    },
    setInternalNotes (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'internalNotes', payload: val })
    },
    saveInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify(this.$t('error.default'))
        return
      }
      this.loading = true
      this.$store.dispatch('onwardtravel/saveAdditionalInfo')
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'ondemand-onwardtravel-summary' })
        })
        .catch(err => {
          this.loading = false
          handleErrors(err)
        })
    }
  },
  validations: {
    mainPassenger: {
      firstName: { required },
      surname: { required },
      mobile: { required }
    },
    racDetails: {
      customer_reference: { required },
      business_area: { required },
      cost_centre: { required },
      vehicle_type: { required },
      vehicle_registration: { required },
      agreement_reference: { required }
    }
  }
}
</script>
