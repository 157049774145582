<template>
  <q-page>
    <div class="layout-padding">
      <div v-if="searching" class="loading">
        <q-spinner-geo class="loader" />
        <p>{{ $t('loading.searching.taxi') }}</p>
      </div>
      <div v-else class="results">
        <!-- Route Info -->
        <div v-if="routeInfo && (vehicleGroups && vehicleGroups.length)" class="row justify-center q-col-gutter-xl q-pb-md">
          <div class="text-right">
            <q-item-label>
              {{ $t('onwardtravel.estimated_distance') }}
            </q-item-label>
            <q-item-label caption>
              <strong>{{ Number(routeInfo.distance.miles).toFixed(2) }}</strong> miles
            </q-item-label>
          </div>
          <div>
            <q-item-label>
              {{ $t('onwardtravel.estimated_trip_duration') }}
            </q-item-label>
            <q-item-label caption>
              <strong>{{ routeInfo.duration.text.split(" ")[0] }}</strong> {{ durationFormat }}
            </q-item-label>
          </div>
        </div>
        <div v-if="!vehicleGroups || !vehicleGroups.length" class="text-center row justify-center">
          <div class="col-12">
            <h5>{{ $t('no_results_found') }}</h5>
          </div>
          <div class="col-12">
            <h6>{{ $t('onwardtravel.concierge_prompt') }}</h6>
          </div>
          <div class="col-12 col-sm-6">
            <q-btn
              class="full-width"
              color="primary"
              size="lg"
              @click="$router.push( { hash: '/concierge/onwardtravel' } )"
            >
              {{ $t('complete_request') }}
            </q-btn>
          </div>
        </div>

        <!-- Results per category -->
        <div v-for="(group, categoryIndex) in groups" :key="group.category_label" class="row q-col-gutter-sm">
          <div class="grouptitle full-width">
            {{ group.category_label }}
          </div>
          <vehicle-card
            v-for="(choice, index) in choices(group.options)"
            :key="index"
            :vehicle="choice"
          />
          <concierge-card v-if="!(categoryIndex % 2)" @click="$router.push( { hash: '/concierge/onwardtravel' } )" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import vehicleCard from './vehicle-card'
import conciergeCard from './concierge-card'
import QSpinnerGeo from 'components/MSpinner/Geo'
export default {
  components: { vehicleCard, conciergeCard, QSpinnerGeo },
  computed: {
    ...mapGetters({
      vehicleGroups: 'onwardtravel/vehicleGroups',
      routeInfo: 'onwardtravel/routeInfo',
      searching: 'onwardtravel/searching'
    }),
    durationFormat () {
      if (!this.routeInfo) return
      const split = this.routeInfo.duration.text.split(' ')
      split.shift()
      return split.join(' ').trim()
    },
    groups () {
      if (!this.vehicleGroups || !this.vehicleGroups.length) return []
      let groups = [...this.vehicleGroups]
      groups = groups.sort((a, b) => a.cheapestVehiclePrice - b.cheapestVehiclePrice)
      return groups
    }
  },
  methods: {
    confirm () {
      this.$store.dispatch('onwardtravel/confirmDetails')
        .then(() => {
          this.$router.push({ name: 'ondemand-ride-hailing-summary' })
        })
    },
    getResults () {
      this.$store.dispatch('onwardtravel/search')
    },
    choices (options) {
      if (!options || !options.length) return []
      let choices = [ ...options ]
      choices.sort((a, b) => a.price.amount - b.price.amount)
      return choices
    }
  }
}
</script>

<style lang="stylus" scoped>

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loading h5
  text-align center
  text-transform uppercase

h4, h6
  text-align center

h4
  margin-top 20px
h6
  margin-bottom 20px

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loader
  width: 100px
  height: 100px
  margin: auto

.grouptitle
  border-bottom 1px solid rgba(black, 0.125)
  font-size 2em
  font-weight 350
</style>
